import { Link, StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Z_BLOCK } from "zlib";
import styles from "./header.module.css";

const Header = ({ siteTitle }) => (
  <StaticQuery query={graphql`
    query{
      site{
        siteMetadata{
          authentication{
            uri
          }
        }
      }
    }
  `} render={data => (
    <header className={styles.heading}>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: '90vw',
          padding: `1.45rem 0`,
          overflow: "auto"
        }}
      >
        <span className={styles.logo}>Aunset</span>

        <a href={"https://login.aunset.com/login?response_type=token&client_id=69p9tlf7al3ekj67pr9jetufon&redirect_uri=https://www.aunset.com"} className={styles.button} style={{float: "right",}}>Login</a>
      </div>
      <nav className={styles.navBar}>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/">Something</Link></li>
        </ul>
      </nav>
    </header>)
  }></StaticQuery>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
